

export const resources = {
  tm: {
    translation: {
      'loading-please-wait':'Maglumatlar ýüklenýär',
      ////////header//////////////////////////
      'check-order':'Ýüzlenmäni barlamak',
      'online-application-for-creating-plastic-card':'Plastik karty almak üçin onlaýn ýüzlenme',
      'order-online-btn':'Onlaýn ýüzlenme',
      'more-btn':'Giňişleýin',
      'help':'Kömek',
      ///////////////forms/////////////////
      'name':'Ady',
      'surname':'Familiýasy',
      'middle-name':'Atasynyň ady',
      'passport':'Pasport belgisi',
      'givenDate':'Pasportyň berlen senesi',
      'givenPlace':'Pasportyň berlen ýeri',
      'birthDate':'Doglan senesi',
      'livingPlace':'Ýaşaýan salgysy',
      /////////////////////////////////////
      'card-application':'Kart almak üçin ýüzlenme',
      'credit-application':'Karz almak üçin ýüzlenme',
      'services':'Hyzmatlar',
      "branch":'Şahamça',
      "phone":'Telefon',
      "email":'Email',
      "address":'Salgy',
      "breakTime":"Arakesme",
      "workShift":"Müşderiler bilen işlenilýän wagty",
      "show-in-map":'Kartada görkez',
      'date':'Senesi',
      ////////////////////3orderCard////////////
      'select-card-app':'Ýüzlenmäniň görnüşini saýlaň',
      //////////////////errors///////////////////
      'some-errors':'Käbir näsazlyklar',
      /////////validations////////////////////
      'required': 'Bu hökmany',
      'input-correctly':'dogry giriziň',
      'passport-is-used-already':'Bu pasport belgisi eýýäm ulanylýar',
      'passport-was-success-checked':'Passport üstünlikli barlanyldy',
      'isChecking':'Barlanylýar',
      'notFound':'Tapylmady',
      ////////////Server-messages///////////
      'success-submitted':'Üstünlikli ugradyldy',
      'success-order-activated':'Siziň ýüzlenmäňiz üstünlikli kabul edildi',
      'success-offered':'Siziň ýüzlenmäňiz kabul edildi',
      'invalid-activate-code':'Bir gezeklik kody dogry giriziň',
      'invalid-credentials':'Girizilen maglumatlar nädogry',
      /////////////////applicant///////////
      'card-type':'Kartyň görnüşi',
      'card-create-type':'Ýüzlenmäniň görnüşi',
      'operation':'Operasiýa',
      'create-date':'Döredilen senesi',
      'performer':'Ýerine ýetiriji',
      'comment':'Bellik',
      'deliveryman':'Çapar',
      //////////////////////payment/////////////
      'openAccount':'Hasaby açmak',
      'cardIssuance':'Karty çap etmek',
      'pinConvertPrint':'Pin-bukjasyny çap etmek',
      'smsMessages':'Sms ýollanmalary',
      'total': 'UMUMY',
      ///////////////////////////////////////////
      'toPay':"Tölemek",
      'payment':"Töleg",
      "reject payment":"Tölegi ret etmek",
      'hyzmat':"Hyzmat",
      'bahasy':"Bahasy",
      'manat':"manat",
      "ýylyň nyrhnamanyň bölümine esaslanylan":"01.01.2022 ýylyň nyrhnamanyň 9-njy bölümine esaslanylan",
      'GBUS goterim':"GBÜS 15%",
      "next":"Öňe",
      "back":"Yza",
      "checkout":"Sargyt etmek",
      "attention":"Üns beriň",
      "refuse":"Ret etmek",
      "continue":"Dowam etmek",
      'private data':"Şahsy maglumatlar",
      "bank branch":"Bankyň şahamçasy",
      "living region":"Ýaşaýan ýeri",
      "send form":"Ugratmak",
      "send code again":"Kody täzeden ugratmak",
      "send code again in":"Kody täzeden ugratmak ýene",
      "confirm sending form":"Maglumatlary ugratmaklygy tassyklaň",
      "confirm code sent to phone":"Tassyklama kody şu telefon belgä ugradyldy",
      "check the correctness of data":"Girizilen şahsy maglumatlaryň dogrulygyny barlaň.",
      "select application type":"Sargydyň görnüşini saýlaň",
      "print PIN convert":"PIN-bukjany çap etmek",
       //////////////////////////new inputs/////////
       "phone number":"Telefon belgisi",
       "no thirdname":"Atasynyň ady ýok",
       "not enough age":"Ýeterlik ýaşa ýetmedik",
       "check correctness of given date":"Berlen senesiniň dogrulygyny barlaň",
       "given date isnt impossible in future":"Berlen senesiniň dogrulygyny barlaň",
       "given by":"Kim tarapyndan berildi",

       /////////////modal/main card modal////////////
       "yuzlenmani saylan":"Ýüzlenmäni saýlaň",
       "yuzlenmani doretmek":"Ýüzlenmäni döretmek",
       "doredilen yuzlenme":"Döredilen ýüzlenme",
       "yapmak":"Ýapmak",

        ///////////////////modal / payment card
        "sargyt etmek toleglidir_1":"Bank kartyny onlaýn sargyt etmek hyzmaty",
        "sargyt etmek toleglidir_ssylka":"töleglidir",
        "sargyt etmek toleglidir_2":"kartyň tölegi onlaýn görnüşde amala aşyrylýar. Sargyt boýunça karty täze çap etmek / gaýtadan çap etmek üçin sargyt goýmagyň hökmany şertleri şulardan ybarat, sargyt tölenjek kartda SMS habarnamana hökman birikdirilen bolmalydyr. Mundan başga-da, töleg geçirjek bank kartyň  CVC koduny bilmek zerurdyr.",
        
        //////////////////////////components / ordercardpage
      "toleg ulgamy":"Töleg ulgamy",
      "kartyn walyutasy":"Kartyň walýutasy",
      ////////////////// ../ orderformthird
      "dowulen karty yzyna gaytarjakdygyma borclanyaryn":"Döwülen kartymy bankyňyza getirjekdigime borçlanýaryn",
      
      //////////////redux card reducer
      "sargyt edijinin maglumatlary_1":"Sargyt edijiniň şahsy maglumatlary",
      "sargyt edijinin pasport maglumatlary_2":"Sargyt edijiniň pasport maglumatlary",
      "yuzlenmanin gornusinin saylamak_3":"Ýüzlenmäniň görnüşini saýlamak",
      //////////////////modal/checkOrder

      "yuzlenmanin belgisi":"Ýüzlenmäniň belgisi",
      "tiket belgisi":"Tiket belgisi",
      "girmek":"Girmek",
      //////applicantPage / header
      "status":"Status",
      //////applicantPage / body
      "yuzlenme":"Ýüzlenme",
      "sargyt beriji":"Sargyt ediji",
      "yuzlenmani tolemek":"Ýüzlenmäni tölemek",
      "duzetmek":"Düzetmek",
      "yuzlenmani duzetmek":"Ýüzlenmäni düzetmek",
      "yuzlenmani ret etmek":"Ýüzlenmäni ret etmek",
      "taze yuzlenme":"Täze ýüzlenme",
      "yok":"Ýok",
      "hawa":"Hawa",
      "yatda sakla":"Ýatda sakla",
      "Yuzlenma dolan":"Ýüzlenmä dolan",
      "maglumatlar dogry duzedilmedi":"Maglumatlar dogry düzedilmedi",


      /////////////foooter/ footer bottom

      "all rights reserved":"Ähli hukular goragly",

      /////////header/headpanel
      "guest":"Şahsy otag",
      ///////////////////////common/branchInfo
      "karty bankyn boluminde alyp bilersiniz":"Kartany \"Türkmenistan\" bankynyň bölüminde alyp bilersiňiz",
      "ish gunleri":"Iş günleri",
      "shenbe gunler":"Şenbe güni",
      "dync gunler":"Ýekşenbe güni",
      "dync":"Dynç",
      "exit":"Çykmak",
      "back to applicant":"Ýüzlenmä dolan",
      ////////////////components/applicantPage/readyCardModal
      "card is ready_title":"Sargyt edilen kartyňyz taýýarlanyldy. Siz ony bankyň şahamçalarynda alyp ýa-da size amatly ýere we amatly wagtda eltip berme hyzmatyndan peýdalanyp bilersiňiz",
      "yanynyz bilen passportynyzy we onun nusgasyny almaklygy unutman":"Ýanyňyz bilen paspotyňyzyň we onuň nusgasyny almaklygy unutmaň",
      "exist passport and broken card":"Ýanyňyz bilen paspotyňyzyň we onuň nusgasyny, döwülen karty almaklygy unutmaň",
      "take it on branch":"Bankyň şahamçasynda almak",
      "take it on delivery":"Eltip berme hyzmatyndan peýdalanmak",
      "Check file type": "Тип файла должен быть JPEG, JPG",
      "maxSizeError": "Faýlyň göwrümi 10mb-dan az bolmaly",
      "dropPassportScan": "Pasportyň nusgasyny ýükläň",
    },
  },
  ru: {
    translation: {
      'loading-please-wait':'Загрузка',
      ////////header//////////////////////////
      'check-order':'Проверить заявку',
      'online-application-for-creating-plastic-card':'Онлайн-заявка на изготовление пластиковой карты',
      'order-online-btn':'Заказать онлайн',
      'more-btn':'Подробнее',
      'help':'Помощь',
      ///////////////forms/////////////////
      'name':'Имя',
      'surname':'Фамилия',
      'middle-name':'Отчество',
      'passport':'Паспортная серия',
      'givenDate':'Дата выдачи паспорта',
      'givenPlace':'Место выдачи паспорта',
      'birthDate':'Дата рождения',
      'livingPlace':'Адрес проживания',
      /////////////////////////////////////
      'card-application':'Заявка на карту',
      'credit-application':'Заявка на кредит',
      'services':'Сервисы',
      "branch":'Филиал',
      "phone":'Tелефон',
      "email":'Email',
      "address":'Адрес',
      "breakTime":"Перерыв",
      "workShift":"Часы работы с клиентами",
      "show-in-map":'Показать на карте',
      'date':'Дата',
       ////////////////////3orderCard////////////
       'select-card-app':'Выберите тип заявки',
       //////////////////errors///////////////////
      'some-errors':'Некоторые ошибки',
      /////////validations////////////////////
      'required': 'Это объязательное поле',
      'input-correctly':'Введите корректно',
      'passport-is-used-already':'Паспортная серия уже используется',
      'passport-was-success-checked':'Паспорт успешно проверен',
      'isChecking':'Проверяется',
      'notFound':'не найдено',
      ////////////Server-messages///////////
      'success-submitted':'Успешно отправлено',
      'success-order-activated':'Ваша заявка успешно активирована',
      'success-offered':'Ваша заявка принята',
      'invalid-activate-code':'Неправильный код потверждения',
      'invalid-credentials':'Неправильные данные',
      /////////////////applicant///////////
      'card-type':'Тип карты',
      'card-create-type':'Тип заявки',
      'operation':'Операция',
      'create-date':'Дата создания',
      'performer':'Исполнитель',
      'comment':'Замечание',
      'deliveryman':'Доставщик',
      //////////////////////payment/////////////
      'openAccount':'Открытие счета',
      'cardIssuance':'Выпуск карты',
      'pinConvertPrint':'Печать ПИН-конверта',
      'smsMessages':'СМС-сообщения',
      'total': 'ИТОГ',
      ///////////////////////////////////////////
      'toPay':"Оплатить",
      'payment':"Платёж",
      "reject payment":"отказ от оплаты",
      'hyzmat':"Услуга",
      'bahasy':"Стоимость",
      'manat':"манат",
      "ýylyň nyrhnamanyň bölümine esaslanylan":"Согласно главе 9 тарифов от 1 января 2022 года",
      'GBUS goterim':"Включая НДС 15%",
      "next":"Вперед",
      "back":"Назад",
      "checkout":"Оформить",
      "attention":"Внимание",
      "refuse":"Отказаться",
      "continue":"Продолжить",
      'private data':"Личные данные",
      "bank branch":"Подразделение банка",
      "living region":"Регион проживания",
      "send form":"Отправить",
      "send code again":"Отправить код повторно",
      "send code again in":"Отправить код заново через",
      "confirm sending form":"Подтвердите отправку формы",
      "confirm code sent to phone":"Код потверждения отправлен на номер",
      "check the correctness of data":"Пожалуйста, проверьте правильность введенных персональных данных. В дальнейшем их изменение становится невозможным",
      "select application type":"Выберите тип заявки",
      "print PIN convert":"Напечатать ПИН-конверт",
      //////////////////////////new inputs/////////
      "phone number":"Мобильный телефон",
      "no thirdname":"Нет отчества",
      "not enough age":"Не достаточно возраста",
      "check correctness of given date":"Проверьте правильность даты выдачи",
      "given date isnt impossible in future":"Дата выдачи не может быть в будущем",
      "given by":"Кем выдано",


       /////////////modal/main card modal////////////
       "yuzlenmani saylan":"Выберите заявку",
       "yuzlenmani doretmek":"Создать заявку",
       "doredilen yuzlenme":"Существующая заявка",
       "yapmak":"Закрыть",


       ///////////////////modal / payment card
       "sargyt etmek toleglidir_1":"Онлайн заказ карты является",
       "sargyt etmek toleglidir_ssylka":"платной услугой",
       "sargyt etmek toleglidir_2":"оплата производится так же в онлайн режиме. Обязательным условием для оформления заказа на выпуск/перевыпуск карты является наличие подписки на СМС-информирование на ту карту, с которой будет производиться оплата заказа. Помимо этого, убедитесь, что для этой карты известен CVC-код.",
       

       //////////////////////////components / ordercardpage
      "toleg ulgamy":"Платежная система",
      "kartyn walyutasy":"Валюта карты",

      ////////////////// ../ orderformthird
      "dowulen karty yzyna gaytarjakdygyma borclanyaryn":"Обязуюсь вернуть банку поломанную карту",
        
       //////////////redux card reducer
       "sargyt edijinin maglumatlary_1":"Персональные данные заказчика",
       "sargyt edijinin pasport maglumatlary_2":"Паспортные данные заказчика",
       "yuzlenmanin gornusinin saylamak_3":"Выбор типа заявки",

       //////////////////modal/checkOrder

       "yuzlenmanin belgisi":"Номер заявки",
       "tiket belgisi":"Тикет",
        "girmek":"Войти",


        //////applicantPage / header
        "status":"Статус",
        //////applicantPage / body
        "yuzlenme":"Заявка",
        "sargyt beriji":"Заказчик",
        "yuzlenmani tolemek":"Оплатить заявку",
        "duzetmek":"Исправить",
        "yuzlenmani duzetmek":"Исправить заявку",

        "yuzlenmani ret etmek":"Отменить заявку",
        "taze yuzlenme":"Новая заявка",
        "yok":"Нет",
        "hawa":"Да",
        "yatda sakla":"Сохранить",
        "Yuzlenma dolan":"Назад на заявку",
        "maglumatlar dogry duzedilmedi":"Не все данные были изменены",


        /////////////foooter/ footer bottom

      "all rights reserved":"Все права защищены",

      /////////header/headpanel
      "guest":"Гость",

      ///////////////////////common/branchInfo
      "karty bankyn boluminde alyp bilersiniz":"Карту можно получить в подразделении банка «Туркменистан»",
      "ish gunleri":"Будние дни",
      "shenbe gunler":"По субботам",
      "dync gunler":"По воскресеньям",
      "dync":"Выходной",
      "exit":"Выход",
      "back to applicant":"Назад на заявку",

////////////////components/applicantPage/readyCardModal
      "card is ready_title":" «Заказанная Вами карта изготовлена. Вы можете получить ее в отделении банка или заказать доставку в удобное для Вас время и место»",
      "yanynyz bilen passportynyzy we onun nusgasyny almaklygy unutman":"При получении карты иметь при себе оригинал и копию паспорта",
      "exist passport and broken card":"При получении карты иметь при себе оригинал и копию паспорта, а так же поломанную карту",
      "take it on branch":"Получить в подразделении банка",
      "take it on delivery":"Заказать доставку карты",
      "Check file type": "Faýl görnüşi JPEG, JPG bolmaly",
      "maxSizeError": "Файл должен быть меньше 10мб",
      "dropPassportScan": "Загрузите копию паспорта",
    },
  },
  // en: {
  //   translation: {
  //     'loading-please-wait':'Loading',
  //     ////////header//////////////////////////
  //     'check-order':'Check order',
  //     'online-application-for-creating-plastic-card':'Online application for the production of a plastic card',
  //     'order-online-btn':'Order online',
  //     'more-btn':'More details',
  //     'help':'Help',
  //     ///////////////forms/////////////////
  //     'name':'Name',
  //     'surname':'Surname',
  //     'middle-name':'Middle name',
  //     'passport':'Passport',
  //     'givenDate':'Date of issue',
  //     'givenPlace':'Place of issue',
  //     'birthDate':'Date of Birth',
  //     'livingPlace':'Residence address',
  //     /////////////////////////////////////
  //     'card-application':'Card-application',
  //     'credit-application':'Credit-application',
  //     'services':'Services',
  //     "branch":'Branch',
  //     "phone":'Phone',
  //     "email":'Email',
  //     "address":'Address',
  //     "workShift":"Work shift",
  //     "show-in-map":'Show in map',
  //     'date':'Date',
  //      ////////////////////3orderCard////////////
  //      'select-card-app':'Select request type',
  //      //////////////////errors///////////////////
  //     'some-errors':'Some errors',
  //     /////////validations////////////////////
  //     'required': 'This is required',
  //     'input-correctly':'Input correctly',
  //     'passport-is-used-already':'Passport series is already in use',
  //     'passport-was-success-checked':'Passport successfully verified',
  //     'isChecking':'Cheking',
  //     'notFound':'not found',
  //     ////////////Server-messages///////////
  //     'success-submitted':'Successfully sent',
  //     'success-order-activated':'Your application has been successfully activated',
  //     'success-offered':'Your application is accepted',
  //     'invalid-activate-code':'Invalid confirmation code',
  //     'invalid-credentials':'Invalid credentials',
  //     /////////////////applicant///////////
  //     'card-type':'Card type',
  //     'card-create-type':'Application type',
  //     'operation':'Operation',
  //     'create-date':'Create type',
  //     'performer':'Performer',
  //     'comment':'Comment',
  //     'deliveryman':'Deliveryman',
  //     //////////////////////payment/////////////
  //     'openAccount':'Opening an account',
  //     'cardIssuance':'Card issuance',
  //     'pinConvertPrint':'PIN envelope printing',
  //     'smsMessages':'SMS messages',
  //     'total': 'TOTAL',

  //     ///////////////////////////////////////////
  //     'toPay':"Pay",
  //     'payment':"Payment",
  //     "reject payment":"Reject payment",
  //     'hyzmat':"Service",
  //     'bahasy':"Price",
  //     'manat':"manat",
  //     "ýylyň nyrhnamanyň bölümine esaslanylan":"According to chapter 9 of tariffs dated January 1, 2022",
  //     'GBUS goterim':"Including VAT 15%",
  //     "next":"Next",
  //     "back":"Back",
  //     "checkout":"Checkout",
  //     "attention":"Внимание",
  //     "refuse":"Refuse",
  //     "continue":"Continue",
  //     'private data':"Private data",
  //     "bank branch":"Bank branch",
  //     "living region":"Living region",
  //     "send form":"Send",
  //     "send code again":"Send code again",
  //     "send code again in":"Send code again via",
  //     "confirm sending form":"Confirm form submission",
  //     "confirm code sent to phone":"Confirmation code sent to number",
  //     "check the correctness of data":"Please check the correctness of the entered personal data. In the future, their change becomes impossible.",
  //     "select application type":"Select application type",
  //     "print PIN convert":"Print PIN envelop",

  //      //////////////////////////new inputs/////////
  //      "phone number":"Phone number",
  //      "no thirdname":"No patronymic",
  //      "not enough age":"No enough age",
  //      "check correctness of given date":"Check correctness of given date",
  //      "given date isnt impossible in future":"Check correctness of given date",
  //      "given by":"Given by"

  //   },
  // },
};
